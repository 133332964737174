<template>
	<div v-if="items.length === 0">
		<div class="text-center mt-3">{{ getTrad('global.no_result') }}</div>
	</div>
	<div v-else class="row card_horse_container">
		<div
			class="col-6 col-md-4 col-xl-3 mt-lg-4 mt-2"
			v-for="(item, idx) in items"
			:key="`card-item-${item[primaryKey]}`"
		>
			<div
				:class="{
					isActive: item.isActive,
					'bloc_item': true
				}"
				@click="$emit('row-clicked', item, idx, $event)"
			>
				<div>
					<template v-for="field in fields">
						<ItemCell
							:key="field.key"
							v-if="rowToEditSlots[`cell(${field.key})`]"
							:data="{
								item: item,
								value: item[field.key]
							}"
							:customConf="rowToEditSlots[`cell(${field.key})`]"
							:colName="field.key"
							:previewColClass="previewColClass"
							:autoConstructUrl="autoConstructUrl"
						/>
						<span 
							v-else
							:key="field.key"
						>
							{{ item[field.key] | prettyField(field.key) }}
						</span>
					</template>
				</div>

				<OfflineMedia
					customClass="rounded w-img"
					defaultMedia="img/horse_placeholder.jpg"
					:filename="item[mediaField]"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Table from '@/mixins/Table'

export default {
	name: 'CustomTableCards',
	mixins: [Table],
	components:{
		ItemCell: () => import('GroomyRoot/components/Table/ItemCell'),
		OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia')
	},
	props: [
		'primaryKey',
		'items',
		'fields',
		'mediaField',
		'rowToEditSlots',
		'previewColClass',
		'autoConstructUrl'
	]
}
</script>

<style>
.w-img {
	max-width: 100px;
}
</style>